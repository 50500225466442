<template>
  <div class="menu_wrapper_container box-shadow">
    <el-row class="table-top">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <h4 class="heading">Live M2M under: Client Portfolio</h4>
      </el-col>
    </el-row>
    <br />
    <el-row style="margin-bottom: 0px !important">
      <el-col :xs="24" :lg="24" :xl="24">
        <div class="box-cell">
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Ledger balance</h1>
              <h2>{{ ledgerBalance }}</h2>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Margin Used</h1>
              <h2>{{ margin }}</h2>
            </div>
          </div>
          <div class="box-single-cell">
           
            <div class="box-cell-content">
               <h1>Un-realiazed P/L</h1>
              <h2>{{ unRealiazedPl }}</h2>
            </div>
          </div>
          <div class="box-single-cell">
            
            <div class="box-cell-content">
              <h1>Realiazed P/L</h1>
              <h2>{{ realiazedPl }}</h2>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <br />
    <el-row>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <h4 class="heading" style="color:#fff;">Active Trades</h4>
        <br>
      </el-col>
    </el-row>
    <Table
      tableKey="closePositions"
      :tableConfig="configActiveTrades"
      :tableData="activeTrades"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
    />
    <br />
    <br />
    <el-row>
      <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
        <h4 class="heading" style="color:#fff;">Close Trades</h4>
        <br>
      </el-col>
    </el-row>
    <Table
      tableKey="closedTrades"
      :tableConfig="configClosedTrades"
      :tableData="closedTrades"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
    />
  </div>
</template>



<script>
import Table from "@/components/Table/Table.vue";
import { fetchList, clientPortfolio } from "@/api/dashboard";
import { mapGetters } from "vuex";
import getRoleSlug from "@/store/app.js";

export default {
  name: "Dashboard",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      configActiveTrades: [
        {
          label: "Script",
          prop: "stock_name",
          width: "",
          className: "redFont",
        },
        {
          label: "Type",
          prop: "type",
          width: "",
          className: "blueFont",
        },
        {
          label: "Lots",
          prop: "lot_and_unit",
          width: "",
          className: "blueFont",
        },
         {
          label: "Margin",
          prop: "margin",
          width: "",
          className: "blueFont",
        },
        {
          label: "Entry Price",
          prop: "avg_price",
          width: "",
          className: "blueFont",
        },
        {
          label: "CMP",
          prop: "cmp",
          width: "",
          className: "blueFont",
        },
        {
          label: "Profit / Loss",
          prop: "stock_pl",
          width: "",
          className: "blueFont",
        },
   
      ],
      configClosedTrades: [
        {
          label: "Script",
          prop: "stock_name",
          width: "",
          className: "redFont",
        },
        {
          label: "Avg Buy",
          prop: "avg_buy",
          width: "",
          className: "blueFont",
        },
        {
          label: "Avg Sale",
          prop: "avg_sell",
          width: "",
          className: "blueFont",
        },
        {
          label: "Broking Charge",
          prop: "broking_charge",
          width: "",
          className: "blueFont",
        },
        {
          label: "Profit / Loss",
          prop: "net_pl",
          width: "",
          className: "blueFont",
        },
        {
          label: "Lots",
          prop: "lot_and_unit",
          width: "",
          className: "blueFont",
        },
      ],
      activeTrades: {
        data: {
          item: [],
        },
      },
      closedTrades: {
        data: {
          item: [],
        },
      },

      ledgerBalance: null,
      margin: null,
      realiazedPl: null,
      unRealiazedPl: null,

      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.name);
    this.handleViewClientDetails();
  },
  methods: {
    handleViewClientDetails() {
      // this.loading = true;
      clientPortfolio(this.$route.params.id)
        .then((response) => {
          // console.log(response);
          if (response.data.success === true) {
            this.id = "";
            this.ledgerBalance = response.data.data.ledger_balance;
            this.margin = response.data.data.margin;
            this.realiazedPl = response.data.data.realiazed_pl;
            this.unRealiazedPl = response.data.data.un_realiazed_pl;
            this.activeTrades.data.item = response.data.data.active_trades;
            this.closedTrades.data.item = response.data.data.closed_trades;
            //
            setTimeout(function () {
              this.recursiveCall();
            }, 4500000);
          } else {
            this.formErrors = this.validationErrors(response.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
     recursiveCall() {
      if(this.$route.name == 'admin_dashboard_broker_client_details') {
        this.handleViewClientDetails()
      }
    },


    handleViewDetails(data) {
      console.log(data);
      return this.$router.push(
        "/" + getRoleSlug.state.roleSlug + "/dashboard/broker/client/" + data.id
      );
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getList();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getList();
    },

    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.ActiveBuy = response.data.data.active_buy;
        this.ActiveSell = response.data.data.active_sell;
        this.ActiveUsers = response.data.data.active_users;
        this.Brokerage = response.data.data.brokerage;
        this.BuyTunrover = response.data.data.buy_tunrover;
        this.ProfitLoss = response.data.data.profit_loss;
        this.SellTunrover = response.data.data.sell_tunrover;
        this.TotalTunrover = response.data.data.total_tunrover;
        this.listData.data.item = response.data.data.users_data.users;
        this.loading = false;
      });
    },
  },
};
</script>
